.news-details-area {
  margin-bottom: 5rem;
}

.news-details-wrap {
  .title1 {
    margin-bottom: 1rem;
  }
  .line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .author {
      display: flex;
      align-items: center;

      :first-child {
        margin-right: 2rem;
      }
      p {
        margin: 0;
      }
    }
  }

  .text-to-speech {
    margin-left: auto;
    margin-right: 0.5rem;
  }
}

.more-news {
  margin-top: 5rem;

  h3 {
    margin-bottom: 1.5rem;
  }

  .cards {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    row-gap: 2rem;
    flex-wrap: wrap;
    width: 100%;
  }

  @media screen and (max-width: 576px) {
    padding: 0 15px;
  }
}
