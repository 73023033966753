.favorite-count {
  color: #000;
  font-family: var(--heading-font);
  font-size: 18px;
  line-height: 22px;
  padding: 0 20px;

  margin-top: 2rem;
  margin-bottom: 2rem;

  span {
    color: var(--main-color-one);
    font-weight: bold;
  }
}

.custom-float-icon-2 {
  height: 60px;
  width: 60px;
  position: fixed;
  top: 30%;
  right: 10px;
  z-index: 99;

  @media only screen and (max-width: 640px) {
    top: 30%;
  }
}

.white-input input {
  background-color: #fff !important;
}
