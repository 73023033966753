.why-choose-us-area {
  position: relative;

  .single-intro .thumb {
    background: none;
  }
  h2.title {
    color: var(--heading-color);
  }

  p.description {
    max-width: 80%;
    font-size: 18px;
    line-height: 22px;
    color: #999999;
    text-align: center;

    margin-bottom: 8%;
  }

  h4.reason-title {
    font-weight: bold;
    font-size: 24px;

    a {
      color: #000;
    }
  }

  .reason-description {
    color: #999999;
    font-size: 16px;
    line-height: 20px;
  }

  .navigation-phrase {
    display: flex;
    align-items: center;
    color: #999999;
    font-size: 16px;
    position: absolute;
    bottom: 3rem;
    right: 3rem;

    transition: color 0.3s ease-in-out;

    .la-long-arrow-right:before {
      margin-left: 4px;
      font-size: 38px !important;
    }

    &:hover {
      color: #000;
    }
  }
}
