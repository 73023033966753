.container {
  .banner-inner-wrap {
    height: auto;

    .banner-inner {
      .title {
        margin-top: 50px;
        color: #fff;
        font-size: 2.5rem;
      }
    }
  }
}

.rld-main-search {
  .banner-code {
    margin-bottom: 11px;
  }
  .banner-row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 1rem;
  }
  .banner-stage {
    flex: 1 0 195px;
  }

  .banner-category {
    flex: 1 0 130px;
  }
  .banner-type {
    flex: 0 1 171.75px;
    button {
      max-width: 171.75px;
    }
  }

  .banner-bedrooms {
    flex: 0 1 160px;
    button {
      max-width: 160px;
    }
  }

  .banner-districts {
    flex: 0 1 255px;
    button {
      max-width: 255px;
    }
  }
  .banner-price {
    flex: 1 1 164px;
    padding: 0 0.5rem;
    max-width: 80%;
  }

  /*  */

  .rld-single-input {
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  .rld-single-select .single-select {
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;
    margin-bottom: 0;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    height: 45px;
    padding: 8px 35px 8px 16px;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../../assets/icons/icon-dropsown.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1.5em auto, 100%;
  }
}

.sub-title {
  font-weight: 400 !important;
  color: #fff !important;
  max-width: 59% !important;
}

.filters-footer {
  width: 100%;
  align-items: flex-end;
  justify-content: space-between;
  margin: 1rem 0 0;
  row-gap: 1rem;
  column-gap: 60%;

  p {
    cursor: pointer;
    transition: filter 0.3s ease;

    &:hover {
      filter: brightness(0);
    }
  }

  img {
    margin-right: 0.4rem;
  }

  .button-filter {
    flex: 1 1 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: url('./../../../assets/icons/filter-icon.svg');
      background-color: inherit;
      width: 25px;
      height: auto;
      position: static;
      opacity: 1;
      margin-right: 0.5rem;
      transform: translateY(5%);
    }

    &:hover {
      filter: brightness(0.95);

      transition: opacity 300ms ease-in-out;
    }
  }

  .button-search {
    flex: 1 1 150px;
  }
}

.filters-footer-code {
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  margin: 1rem 0 0;
  row-gap: 1rem;
  column-gap: 60%;

  .button-search {
    flex: 1 1 150px;

    @media screen and (min-width: 992px) {
      max-width: 177px;
    }
  }
}
.banner-area {
  height: 662px;

  @media screen and (max-width: 800px) {
    height: auto;
    padding-bottom: 40px;
  }

  @media only screen and (max-width: 470px) {
    h1 {
      font-size: 30px !important;
    }
  }

  @media only screen and (max-width: 396px) {
    h1 {
      font-size: 25px !important;
    }
  }

  @media only screen and (max-width: 335px) {
    h1 {
      font-size: 23px !important;
    }
  }
}
