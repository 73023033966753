.client-area {
  h2.title {
    color: var(--main-color-one);
  }

  .client-slider-2 {
    .thumb {
      margin-bottom: 25px;
      img {
        width: 50px;
      }
    }
  }

  .review-details {
    h4 {
      color: var(--main-color-one);
      margin-bottom: 0.5rem;
    }

    .animate-panel {
      p {
        margin: 0;
        overflow: hidden;
      }
    }
  }
  .arrow-button {
    margin-top: 1rem;
    font-size: 2rem;
    padding: 0.25rem;
    cursor: pointer;
  }

  .owl-controls {
    margin-top: 50px;
  }

  .call-to-action-container {
    text-align: right;
    margin-top: 40px;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  .client-slider-2 .item {
    padding: 0 0.5rem;
  }
}
