.card-container {
  width: 250px;

  height: 200px;

  border-radius: 15px;

  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.15);
  transition: box-shadow 500ms ease;

  &:hover {
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.35);
  }
  .card-link {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .card-image {
      width: 100%;

      align-self: center;
      margin-bottom: 1.5rem;

      img {
        width: 100%;
      }
    }

    .card-text {
      display: flex;
      flex-direction: column;

      row-gap: 0.5rem;
    }
  }
}
