.areal,
.baronesa {
  height: 174px;
  object-fit: cover;
}

.zona-norte {
  height: 450px;
  object-fit: cover;
}
.centro {
  height: 357px;
  object-fit: cover;
}
.laranjal {
  height: 364px;
  object-fit: cover;
}
.sao-goncalo {
  height: 305px;
  object-fit: cover;
}
.recanto {
  height: 298px;
  object-fit: cover;
}

.recanto-wrapper {
  @media screen and (min-width: 1600px) {
    left: 66.9265% !important;
  }
}

.rld-city-item .single-city .thumb img {
  width: 100%;
}
