.property-description {
  &__title {
    color: #000;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 2rem;

    margin-top: 2rem;
  }

  &__text {
    font-size: 16px;
    color: #000;
    line-height: 24px;
    font-weight: 400;
    width: 100%;
    text-align: justify;
  }

  &__main-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 4%;

    @media only screen and (max-width: 992px) {
      flex-direction: column;
    }

    &__description {
      flex: 3;
    }

    &__banner {
      flex: 1;
      height: 70%;
      background: #ffffff;
      border: 1px solid #e4e9f2;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0 4%;

      @media only screen and (max-width: 992px) {
        width: 300px;
        height: 300px;

        padding: 4%;
      }

      h3 {
        width: 100%;
        font-family: var(--heading-font);
        font-size: 18px;
        color: #262839;
        line-height: 20px;
      }

      a {
        width: 100%;
        font-family: var(--heading-font);
        font-size: 32px;
        line-height: 35px;
        color: var(--main-color-one);
        cursor: pointer;
        text-decoration: none;

        font-weight: bold;
        text-align: justify;

        &:hover {
          opacity: 0.95;
        }
      }
    }
  }

  &__bold-text {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    line-height: 24px;
  }

  &__section-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 2rem;

    padding-bottom: 2.4rem;
    border-bottom: 1.8px solid var(--main-color-one);

    @media only screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  &__section-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 32px;
    grid-column-gap: 10%;
    align-items: center;

    padding-bottom: 4rem;
    margin-bottom: 2rem;
    border-bottom: 1.8px solid var(--main-color-one);

    @media only screen and (max-width: 780px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__section-infos {
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 10%;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;

    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1.8px solid var(--main-color-one);

    @media only screen and (max-width: 991px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media only screen and (max-width: 580px) {
      grid-template-columns: 1fr;
    }

    &__info {
      .description-infos {
        width: 270px;
        margin-top: 6px;
        margin-left: 2px;

        @media only screen and (max-width: 700px) {
          width: 230px;
        }
      }

      .capitalize {
        text-transform: lowercase;

        &:first-letter {
          text-transform: uppercase;
        }
      }

      &__list {
        margin: 0;
        &__item {
          color: #000;
          font-weight: 500;
          margin-left: 10px;
        }
      }
    }
  }
}
