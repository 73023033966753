.pd-top-30 {
  padding-top: 30px;
}

.md-top-50 {
  margin-top: 50px;
}

.my-team-details {
  min-height: 160px;

  h4 {
    color: var(--main-color-one);
  }

  p {
    padding: 1rem 0 1.5rem;
  }
}
.single-team {
  height: calc(100% - 30px);

  .image-wrapper {
    position: relative;
    img {
      width: 100%;
    }
  }
  .image-hover {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 250ms ease;
  }
  .image-hover:hover {
    opacity: 1;
  }
}
