.react-slideshow-container {
  width: 100% !important;
  position: relative;

  .default-nav {
    padding: 6px !important;
    padding-top: 4px !important;
    padding-right: 8px !important;
    padding-left: 7px !important;

    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    height: 100px;
    width: 50px;

    border-radius: 0;

    &:first-of-type {
      left: 0px;
    }

    &:last-of-type {
      right: 0px;
    }
  }
}

.each-slide {
  img {
    width: 100%;
  }
}
