.single-property {
  border-radius: 10px;
  background-color: #f6f7fb;
  border: 1px solid #d6d6d6;
  display: flex;
  margin-bottom: 20px;

  @media only screen and (max-width: 770px) {
    height: 240px;
  }

  @media only screen and (max-width: 515px) {
    height: 200px;
  }

  @media only screen and (max-width: 390px) {
    height: 160px;
  }

  &__image {
    width: 40%;
    border: 1px solid #d6d6d6;
    border-radius: 10px 0 0 10px;
  }

  &__container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 14px 20px;

    @media only screen and (max-width: 698px) {
      padding: 12px 16px;
    }

    @media only screen and (max-width: 515px) {
      padding: 6px 8px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      grid-gap: 10px;

      @media only screen and (max-width: 515px) {
        margin-bottom: 0;
      }

      &__title {
        font-family: var(--heading-font);
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        color: #000;
        margin: 0;
        max-width: 88%;

        @media only screen and (max-width: 698px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 435px) {
          font-size: 12px;
        }

        @media only screen and (max-width: 390px) {
          font-size: 8px;
        }
      }
    }

    &__infos {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      @media only screen and (max-width: 515px) {
        margin-bottom: 2px;
      }

      &__price {
        font-family: var(--heading-font);
        font-size: 16px;
        line-height: 19px;
        color: #000;
        margin-right: 20%;

        @media only screen and (max-width: 698px) {
          font-size: 14px;
        }

        @media only screen and (max-width: 435px) {
          font-size: 12px;
        }

        @media only screen and (max-width: 390px) {
          font-size: 8px;
        }
      }

      &__code {
        font-family: var(--heading-font);
        font-size: 12px;
        line-height: 14px;
        color: #000;

        @media only screen and (max-width: 435px) {
          font-size: 10px;
        }

        @media only screen and (max-width: 390px) {
          font-size: 8px;
        }
      }
    }

    &__about {
      font-family: var(--heading-font);
      font-size: 13px;
      line-height: 19px;
      color: #000;
      margin-bottom: 12px;

      @media only screen and (max-width: 515px) {
        margin-bottom: 8px;
      }

      @media only screen and (max-width: 435px) {
        font-size: 10px;
      }

      @media only screen and (max-width: 390px) {
        font-size: 8px;
      }

      span:not(:first-child) {
        margin-left: 6px;
        padding-left: 6px;
        border-left: 1px solid black;
      }

      .bold {
        font-weight: bold;
      }
    }

    &__description {
      width: 100%;
      font-family: var(--heading-font);
      font-size: 13px;
      line-height: 14px;
      color: #000;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-wrap: break-word;
      overflow: hidden;

      @media only screen and (max-width: 698px) {
        font-size: 12px;
      }

      @media only screen and (max-width: 435px) {
        font-size: 10px;
      }

      @media only screen and (max-width: 390px) {
        display: none;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      color: #fff;
      background-color: var(--main-color-one);
      font-size: 16px;
      font-weight: bold;
      border: transparent;
      height: 40px;
      width: 60%;
      cursor: pointer;
      transition: background-color 250ms;

      @media only screen and (max-width: 698px) {
        font-size: 14px;
      }

      @media only screen and (max-width: 435px) {
        height: 35px;
        font-size: 10px;
      }

      &:hover {
        background-color: #b8945b;
      }
    }
  }
}
