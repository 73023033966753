.property-details-form__contact {
  .contact {
    .input-error {
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-align: left;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;

      color: #d32f2f;
    }
    .mt-18 {
      margin-top: 18px;
    }
    .rld-single-input {
      input {
        font-size: 1rem;
        line-height: 1.4375rem;
        font-family: 'Helvetica';
        height: 56px;

        background-color: transparent !important;
        padding: 0 14px;
      }

      p {
        color: #d32f2f;
        font-family: 'Helvetica';
        font-size: 0.75rem;
        margin: 3px 14px 0;
      }
    }

    .rld-single-input select {
      height: 48px;
      line-height: 48px;
      width: 100%;
      padding: 0 20px;
      border: 1px solid #d4d4d4;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border-radius: 4px;
      font-size: 15px;
    }
    .rld-single-input select:hover,
    .rld-single-input select:active,
    .rld-single-input select:focus {
      border: 1px solid var(--main-color-one);
    }

    .test-input input:focus {
      border: 0;
    }
    .test-input textarea:focus {
      border: 0;
    }

    .upload__image-wrapper {
      display: flex;
      flex-direction: column;

      .image-input {
        background-color: #fff;
        outline: none;

        padding: 1.5rem;

        font-size: 0.9375rem;
        width: 100%;
        border: 1px solid #d4d4d4;
        -webkit-transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        transition: all 0.4s ease;
        border-radius: 4px;

        margin-bottom: 1rem;

        &:hover,
        &:active,
        &:focus {
          border: 1px solid var(--main-color-one);
        }
      }

      .images-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: center;
        align-items: center;

        gap: 0.5rem;
        flex-wrap: wrap;
      }
      .image-item {
        position: relative;
        &__btn-wrapper {
          position: absolute;
          top: 5px;
          right: 5px;

          color: #c8232c;
          cursor: pointer;
        }

        img {
          object-fit: cover;
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    .service-area-about {
      flex: 3;
    }
    .sell-property-form__contact {
      flex: 1;
    }
  }

  .status-wrapper {
    width: 100%;

    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: var(--heading-font);
    font-size: 1rem;
    line-height: 100%;

    .MuiSvgIcon-root {
      color: var(--main-color-one);
      font-size: 6rem;
      margin-bottom: 0.5rem;
    }
  }
}

.widget-wrapper {
  margin-top: 50px;
  padding-bottom: 50px;

  .widget {
    margin-top: 0 !important;
  }

  @media screen and (max-width: 991px) {
    margin-top: 0;
  }
}

.property-infos {
  display: grid;
  grid-template-columns: 1.8fr 0.3fr;
  grid-gap: 0.5rem;
  padding-top: 2.4375rem;

  margin-bottom: 2.625rem;
  max-height: 330px;

  @media only screen and (max-width: 850px) {
    grid-template-columns: none;
    grid-template-rows: repeat(2, 1fr);
  }

  &__description {
    &__name {
      font-size: 25px;
      font-weight: bold;
      color: var(--main-color-one);
      line-height: 28, 8px;
    }

    &__wrapper {
      display: flex;
      gap: 2rem;
      align-items: center;

      &__location {
        color: #010101;
        font-size: 18px;
        margin-top: 10px;
        text-transform: capitalize;
      }
    }
  }

  &__code {
    max-height: 84px;
    border: 2px solid #c4c4c4de;
    border-radius: 6px;
    color: #999999;
    font-size: 18px;
    padding: 25px 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__info {
      display: inline-block;
      height: max-content;
      width: max-content;
    }
  }
}

.contact {
  &__title {
    color: #353535;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3rem;
  }
}

.contact-info {
  &__title {
    color: #353535;
    font-size: 16px;
    font-weight: bold;

    margin-bottom: 1.5rem;
  }

  &__subtitle {
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    line-height: 0.8rem !important;
  }

  &__specific-info {
    color: #999999 !important;
    line-height: 0.8rem !important;
  }
}

.custom-icon {
  font-size: 18px;
}

.custom-medias-container {
  &__options {
    display: flex;
    max-width: 600px;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 190px;
      height: 50px;
      border: transparent;
      border-radius: 5px 5px 0px 0px;
      background-color: #fff;
      color: var(--main-color-one);
      cursor: pointer;
      transition: background-color 250ms, color 250ms;

      &:not(:last-child) {
        margin-right: 6px;
      }

      &.active,
      &:hover {
        background-color: var(--main-color-one);
        color: #fff;
      }
    }
  }

  &__content {
    height: 85%;
    width: 100%;
    overflow: hidden;

    &__data {
      max-height: 100%;
      background-color: gray;

      &--map,
      &--video {
        height: 500px !important;
        width: 100% !important;
      }

      img,
      iframe {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0px 10px 10px 10px !important;
      }
    }
  }
}

.share-button {
  position: relative;
  left: -1rem;
}
