.company-card .single-user-list {
  min-height: 270px;
  box-sizing: content-box;

  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.12);

  &:hover {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.12);
  }
  display: flex;
  flex-direction: column;

  .thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 1 70px;
    margin: 0 auto 25px;
  }

  .details {
    display: flex;
    flex-direction: column;

    flex: 1;
    p {
      margin-top: auto;
    }
  }
}
