.contact-area {
  .form-wrapper {
    display: flex;
    width: 100%;

    column-gap: 1rem;
  }

  .center-items {
    justify-content: center;
  }
  .single-team {
    height: calc(100% - 30px);

    .image-wrapper {
      position: relative;
      img {
        width: 100%;
      }
    }
    .image-hover {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      transition: opacity 250ms ease;
    }

    .my-team-details {
      min-height: auto;

      h4 {
        color: var(--main-color-one);
      }

      p {
        padding: 1rem 0 1.5rem;
      }
    }
  }
}

.custom-float-icon-simulate {
  z-index: 99;
  position: fixed;
  top: 60%;
  right: 20px;
  height: 60px;
  width: 60px;

  svg {
    height: 60px;
    width: 60px;
  }
}

.checkbox-label .MuiFormControlLabel-root {
  font-size: 0.7rem;
}

.main-color {
  color: var(--main-color-one);
}

.my-container-example {
  margin-bottom: 30px;

  h3 {
    font-weight: bold;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    text-align: justify;
  }
}

.links-to-simulate-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;

  &__link {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    text-align: center;
    padding: 16px 20px;
    transition: box-shadow 250ms ease;
    box-shadow: 0px 2px 12px #58585813;

    h1 {
      font-size: 2.5rem;
      line-height: 4.5rem;
      font-weight: 700;
    }

    h3 {
      font-size: 1rem;
      line-height: 1.375rem;
      color: black;
    }

    &:hover {
      box-shadow: 0px 2px 12px #5858582f;
    }
  }

  @media only screen and (max-width: 770px) {
    grid-template-columns: 1fr;
  }
}
