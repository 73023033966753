.page-infos-container {
  width: 100%;
  background-color: var(--main-color-one);
}

.page-infos {
  padding: 1rem 0.9375rem;

  display: flex;
  flex-direction: column;
  justify-content: center;

  margin: 0 auto;

  &__page-name {
    margin-bottom: 0;

    font-size: 2rem;
    font-weight: bold;
    color: white !important;
    white-space: nowrap;
  }

  &__navigation-infos {
    font-size: 1.1rem;
    white-space: nowrap;
    margin: 0;

    a {
      color: white;

      &:not(:last-child)::after {
        content: '/';
        margin: 0 4px;
      }

      &:hover {
        color: gray;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    align-items: center;

    &__page-name {
      font-size: 1.2rem;
    }
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }
}
