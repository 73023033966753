.my-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .contact-description-container {
    font-family: var(--heading-font);
    padding: 0 30px;

    .contact-title {
      font-size: 2rem;

      margin-bottom: 1rem;
    }

    .contact-phrase {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      margin-bottom: 0.5rem;
    }

    .margin-bottom {
      margin-bottom: 1rem !important;
    }
  }
}

.w-85 {
  width: 85%;

  margin-bottom: 30px;
}

.pd-top-50 {
  padding-top: 50px;
}

.sell-property-form {
  &__contact {
    &__input {
      background-color: #f6f7fb !important;
    }

    &__button {
      width: 100%;
    }
  }

  .mt-18 {
    margin-top: 18px !important;
  }
}

.form-wrapper {
  display: flex;
  width: 100%;

  margin-bottom: 5rem;

  column-gap: 1rem;
  row-gap: 2rem;

  .status-wrapper {
    width: 100%;

    padding: 1rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: var(--heading-font);
    font-size: 1rem;
    line-height: 100%;
    text-align: center;

    .MuiSvgIcon-root {
      color: var(--main-color-one);
      font-size: 6rem;
      margin-bottom: 0.5rem;
    }
  }

  .rld-single-input {
    input {
      height: 50px;

      background-color: transparent !important;
      padding: 0 14px;
    }

    p {
      color: #d32f2f;
      font-family: 'Helvetica';
      font-size: 0.75rem;
      margin: 3px 14px 0;
    }
  }

  .rld-single-input select {
    height: 48px;
    line-height: 48px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #d4d4d4;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
    border-radius: 4px;
    font-size: 15px;
  }
  .rld-single-input select:hover,
  .rld-single-input select:active,
  .rld-single-input select:focus {
    border: 1px solid var(--main-color-one);
  }

  .test-input input:focus {
    border: 0;
  }

  .upload__image-wrapper {
    display: flex;
    flex-direction: column;

    .image-input {
      background-color: #fff;
      outline: none;

      padding: 1.5rem;

      font-size: 0.9375rem;
      width: 100%;
      border: 1px solid #d4d4d4;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
      border-radius: 4px;

      margin-bottom: 1rem;

      &:hover,
      &:active,
      &:focus {
        border: 1px solid var(--main-color-one);
      }
    }

    .images-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;

      gap: 0.5rem;
      flex-wrap: wrap;
    }
    .image-item {
      position: relative;
      &__btn-wrapper {
        position: absolute;
        top: 5px;
        right: 5px;

        color: #c8232c;
        cursor: pointer;
      }

      img {
        object-fit: cover;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .service-area-about {
    flex: 3;
  }
  .sell-property-form__contact {
    flex: 1;
    margin: 0 1.25rem;

    .loading-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .loading {
        max-width: 55px;

        svg {
          width: 100%;
        }
      }
    }
  }
}
