.header-container {
  background-color: #f6f7fb;
  max-height: 80px;

  @media only screen and (max-width: 630px) {
    display: none;
  }
}

.header-infos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem 15px;
  width: auto;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }

  .contact {
    grid-gap: 2rem;
    margin-right: 7.5rem;

    @media (max-width: 992px) {
      margin-right: 3.5rem;
    }

    @media (max-width: 768px) {
      grid-gap: 1rem;
      margin-right: 1.5rem;
    }
  }

  &__contacts {
    margin: 0;
    grid-gap: 1rem;
    display: flex;
    max-width: max-content;
    max-height: max-content;
    align-items: center;

    .col-sm {
      max-width: max-content;
      max-height: max-content;
      padding: 0;
    }

    p,
    strong {
      font-family: var(--heading-font);
      height: min-content;
      font-size: 1rem;
      color: #000;
      line-height: 120%;
    }

    p {
      font-size: 0.875rem;
      margin: 0;

      @media (max-width: 768px) {
        font-size: 0.75rem;
      }
    }
    strong {
      font-size: 1.125rem;

      @media (max-width: 768px) {
        font-size: 1rem;
      }
    }
  }

  &__button-container {
    display: flex;
    padding: 0;
    max-width: max-content;
    align-items: center;
    cursor: pointer;

    button {
      font-family: var(--heading-font);

      font-size: 1rem;
      font-weight: bold;
      padding: 0rem 1rem;
      height: min-content;

      border-radius: 5px;
    }

    svg {
      margin-left: 2rem;
      vertical-align: middle;

      @media (max-width: 768px) {
        margin-left: 1rem;
      }

      &:hover {
        filter: brightness(1.4);
      }
    }
  }
}
