.featured-area {
  margin-top: 75px;
}

.property-title {
  line-height: 1.7rem;
  margin-bottom: 0 !important;

  &:hover {
    a {
      color: var(--main-color-one) !important;
    }
  }

  a {
    font-size: 1.375rem;
    font-weight: bold;
    color: white !important;
  }

  &--little {
    font-size: 18px !important;

    a {
      color: #999999 !important;
    }
  }

  strong {
    margin-left: 4px;
    font-size: 0.875rem !important;
  }
}

hr {
  background-color: var(--main-color-one) !important;
}

.price {
  font-size: 2rem;
  font-weight: bold;

  &--little {
    font-size: 1rem;
    line-height: 1.2rem;
    color: #000 !important;
    font-weight: bold;
  }
}

.infos {
  margin-top: 8px;
  padding: 0 1rem;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  .infos-head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    margin-bottom: 0.5rem;

    h5 {
      margin-bottom: 0;
    }
  }

  .infos-description {
    display: flex;
    column-gap: 1.4rem;
    flex-wrap: wrap;
  }

  strong {
    color: white;
    text-transform: none;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  span {
    color: white;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.5rem;
    color: white;

    @media screen and (max-width: 520px) {
      font-size: 0.85rem;
    }

    &:hover {
      color: var(--main-color-one);
    }
  }

  .icon-arrow {
    margin-left: 1rem;
    font-size: 1.375rem;
  }

  &--little {
    color: #999999 !important;
    text-transform: none;
    font-size: 14px !important;
    justify-content: space-between;
    padding: 0 1rem;

    :first-letter {
      text-transform: uppercase;
    }
  }
}
.single-leading-feature .details {
  font-family: var(--heading-font);
  font-style: normal;
  width: 100%;

  .title {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 120%;
    color: #ffffff;

    @media screen and (max-width: 520px) {
      font-size: 1.15rem;
    }
  }

  .image-price {
    color: white;
    font-weight: bold;
    font-size: 2.02rem;
    line-height: 120%;

    @media screen and (max-width: 520px) {
      font-size: 1rem;
    }
  }
}

.single-feature .details .title {
  font-family: var(--heading-font);
  font-style: normal;
  font-size: 1rem;
  line-height: 120%;
  color: #999999;
}

.single-feature {
  margin: 0 !important;
}

.mb-15 {
  margin-bottom: 15px;
}

.image-thumb {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;

  &--main {
    height: 100% !important;

    img {
      height: 100%;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.image-details-feature-properties {
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  row-gap: 19px;

  span {
    margin-bottom: auto !important;
  }

  h6,
  #line {
    margin: 0 !important;
  }

  #line {
    width: 100%;
    height: 1px;
    background-color: var(--main-color-one);
  }
}
