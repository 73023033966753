.ReactModal__Overlay {
  z-index: 9 !important;
}

.row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-title {
  font-size: 28px;
  color: #222222;
  font-family: var(--heading-font);
}

.modal-close-icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.modal-subtitle {
  color: #222222;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;

  @media only screen and (max-width: 738px) {
    margin: 24px 0 14px 0;
  }
}

.column-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 6px;

  @media only screen and (max-width: 738px) {
    grid-template-columns: repeat(2, 1fr);

    &__item {
      margin-top: 12px !important;
    }
  }

  @media only screen and (max-width: 470px) {
    grid-template-columns: 1fr;
  }

  &__item {
    display: flex;
    align-items: center;

    &__checkbox {
      margin-right: 8px;
      height: 24px;
      width: 24px;
    }

    input:not(:checked) {
      filter: invert(50%) brightness(1.7);

      &:hover {
        filter: invert(50%) brightness(1.5);
      }
    }

    &__label {
      color: #000;
      font-size: 14px;
      font-family: var(--heading-font);
      margin: 0;
    }
  }
}

.mt-20 {
  margin-top: 20px;
}

.modal-submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  font-family: var(--heading-font);
  background-color: var(--main-color-one);
  width: 100%;
  height: 48px;
  border: transparent;
  margin-top: 40px;
  cursor: pointer;
  transition: background-color 250ms;

  &:hover {
    background-color: #a78959;
  }
}
