.cookies-row {
  position: fixed;
  width: 100%;
  min-height: 5rem;
  z-index: 100;
  bottom: 0;
  background-color: var(--main-color-one);
  column-gap: 1rem;
  padding: 1rem 0;

  .cookies-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 15px;

    gap: 1rem;

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1210px;
    }

    @media (min-width: 1600px) {
      max-width: 1360px;
    }

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  &_description {
    font-family: var(--heading-font);
    margin: 0;
    text-align: justify;
    color: white;
  }

  &_link {
    color: white;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }

  &_button {
    cursor: pointer;
    background-color: white;
    height: 48px;
    flex: 0 0 max-content;
    white-space: nowrap;
    text-align: center;
    padding: 0 30px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    border: 0;
    font-size: 14px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-weight: 600;

    @media (max-width: 768px) {
      flex: auto;
    }

    &:focus,
    &:active {
      outline: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0;
      background-color: rgba(255, 255, 255, 0.2);
      -webkit-transform: scale(1, 0.5);
      -ms-transform: scale(1, 0.5);
      transform: scale(1, 0.5);
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    &:hover:before {
      opacity: 1;
      -webkit-transform: scale(1, 1);
      -ms-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
}
