.black-title {
  color: #353535;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.rld-single-input {
  &.with-border-bottom {
    border-bottom: 1px solid #d8d7d7;
    padding: 0 0 2rem 0;
  }
}

.divider {
  height: 1px;
  width: 100%;
  background-color: #d8d7d7;
  margin: 2rem 0 1rem 0;
}
.grid-header-container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding: 0 15px;
}
.filter-menu-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  button {
    background-color: #fff;
    padding: 8px 8px 8px 16px;

    color: #999;
    font-size: 0.875rem;
    line-height: 1.3125rem;

    border: 1px solid #dddddd;
    border-radius: 5px;

    display: flex;
    align-items: center;
    column-gap: 5px;

    cursor: pointer;

    transition: background-color 200ms ease;

    &:hover {
      background-color: #d3d3d393;
    }
  }

  .filter-menu {
    position: absolute;
    bottom: 0;
    width: 100%;

    transform: translateY(100%);

    background-color: white;
    border-radius: 5px;
    border: 1px solid lightgray;

    z-index: 1;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      display: flex;
      flex-direction: column;

      li {
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
          background-color: #d3d3d393;
        }
      }
    }
  }
}

.multiple-fields {
  input:not(:last-child) {
    margin-bottom: 1.5rem;
  }
}

.grid-multiple-fields {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 18px;
  border-bottom: 1px solid #d8d7d7;
  padding: 0 0 2rem 0;
  margin-bottom: 1rem;
}

.row-fields {
  display: flex !important;
  flex-direction: row;

  div {
    max-width: 45%;
  }

  :first-child {
    margin-right: 1rem !important;
  }
}

.prices-container,
.sizes-container {
  display: flex;
  justify-content: space-between;

  &__price,
  &__size {
    font-size: 0.8rem;
    font-weight: bold;
    line-height: 15px;
    color: #999999;
  }
}

.white-filter-icon {
  path {
    stroke: white !important;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
}

.custom-price {
  color: #000 !important;
  font-size: 22px !important;
}

.custom-property-title {
  line-height: 1.7rem;

  &:hover {
    h4 {
      color: var(--main-color-one) !important;
    }
  }

  h4 {
    font-size: 1.125rem;
    line-height: 120%;
    font-weight: bold !important;
    color: #999999 !important;
  }

  &--little {
    font-size: 18px !important;

    a {
      color: #999999 !important;
    }
  }

  strong {
    margin-left: 4px;
    font-size: 14px !important;
  }
}

.custom-infos {
  padding: 0 1rem;
  justify-content: space-between;
  align-items: flex-start;

  .custom-infos-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .code-wrapper {
      display: flex;
      flex-wrap: wrap;
      column-gap: 1.4rem;
    }
  }

  strong {
    color: #999999;
    text-transform: none;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  span {
    color: #999999;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 1rem;
    color: #999999;

    &:hover {
      color: var(--main-color-one);
    }
  }

  .la-long-arrow-right:before {
    margin-left: 4px;
    font-size: 38px !important;
  }

  &--little {
    color: #999999 !important;
    text-transform: capitalize;
    font-size: 14px !important;
    justify-content: space-between;
    padding: 0 1rem;
  }
}

.float-icon {
  position: fixed;
  top: 100%;
  right: 8px;
  z-index: 99;
}

.image-card {
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 576px) {
    height: 270px;
  }

  @media only screen and (max-width: 400px) {
    height: 290px;
  }
}

.custom-float-icon {
  height: 60px;
  width: 60px;
  position: fixed;
  right: 0;
  z-index: 99;

  @media only screen and (max-width: 630px) {
    top: 40%;
  }
}

.custom-thumb {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 230px;
  position: relative;

  img {
    height: 100%;
    width: 100%;
  }

  .favorite-icon {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}

.see-details-link {
  font-weight: 1.5rem;
  font-weight: bold;

  .arrow-icon {
    margin-left: 0.5rem;
  }
}

.slider-mui {
  margin-top: 0.5rem;

  .MuiSlider-root {
    color: rgba(var(--main-color-one), 0.16);
  }
  .MuiSlider-thumb {
    width: 27px;
    height: 15px;
    border-radius: 10px;
    margin-top: -6px;
    margin-left: -12px;

    box-shadow: none;

    &:hover {
      box-shadow: 0px 0px 0px 8px rgba(var(--main-color-one), 0.16);
    }
  }

  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: none;
  }

  .MuiSlider-rail,
  .MuiSlider-thumb,
  .MuiSlider-track,
  .MuiSlider-mark {
    background-color: var(--main-color-one);
  }

  .MuiSlider-valueLabel {
    * {
      background-color: var(--main-color-one);
    }
  }
}

.widget-sidebar-search {
  margin-bottom: 0;

  .rld-single-input {
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;
  }

  .rld-single-select .single-select {
    font-family: var(--heading-font);
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.3125rem;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    height: 45px;
    padding: 8px 35px 8px 16px;

    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('../../../assets/icons/icon-dropsown.svg');
    background-repeat: no-repeat, repeat;
    background-position: right 1em top 50%, 0 0;
    background-size: 1.5em auto, 100%;
  }
}

.search-card-grid {
  padding-right: 12px;
  padding-left: 12px;
}
