.whatsapp-station {
  height: 50px;
  width: 50px;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 99;
  cursor: pointer;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #25d366;

  @media screen and (max-width: 575px) {
    width: 45px;
    height: 45px;
    right: 15px;
  }
}
