.service-area {
  width: 100%;

  padding-bottom: 0 !important;
}
.service-area-about {
  padding: 0 30px;
  overflow: hidden;
}

.single-intro {
  .bank-thumb {
    line-height: 68px !important;
  }

  .link {
    h5 {
      transition: all 200ms ease;
    }
    &:hover {
      h5 {
        filter: brightness(0.7);
      }
    }
  }
}
.bank-item {
  height: 180px !important;

  position: relative;

  @media screen and (max-width: 767px) {
    margin: 0 8px;
  }
}
.bank-item::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 29px;
  bottom: 0;
  width: 100%;
  height: 88%;
  -webkit-box-shadow: 0px 2px 12px #97a1b21a;
  box-shadow: 0px 2px 12px #97a1b21a;
  z-index: -1;
  background: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
}

.desktop-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;

  .bank-item {
    position: relative;
  }
  .bank-item::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 29px;
    bottom: 0;
    width: 100%;
    height: 88%;
    -webkit-box-shadow: 0px 2px 12px #97a1b21a;
    box-shadow: 0px 2px 12px #97a1b21a;
    z-index: -1;
    background: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
    border-radius: 10px;
  }
}

.single-intro::after {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
}

.service-slider-2 {
  .thumb img {
    color: white;
    width: 20px;
  }

  .owl-nav div {
    position: absolute;
    top: 45%;
    z-index: 1;

    transform: translateY(-50%);
  }

  .owl-next {
    right: -50px;
  }

  .owl-prev {
    left: -50px;
  }

  .owl-nav button i {
    font-size: 25px;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media screen and (max-width: 767px) {
    .owl-stage-outer {
      overflow: visible;
    }
  }
}
