.property-values-and-infos {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  margin-top: 2.9375rem;

  .little-container-default {
    border: 1px solid #c8c8c8;
    border-radius: 4px;
    padding: 16px;

    .my-custom-title {
      font-weight: bold;
      color: #000;
      font-size: 1.25rem;
      line-height: 1.875rem;
      margin: 0 0 0.5rem;
      padding: 0;

      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    .my-custom-subtitle {
      font-weight: 500;
      color: #000 !important;
      font-size: 1.125rem;
      line-height: 1.6875rem;
    }
  }

  .info-sections-wrapper {
    .my-custom-subtitle,
    .my-custom-title {
      margin: 0 !important;
    }
  }

  &__values {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 5 1 350px;

    &__prices {
      &__info {
        display: flex;
        justify-content: space-between;

        &__value {
          font-weight: bold;
          color: var(--main-color-one);
          font-size: 1.375rem;
          line-height: 1.875rem;
        }
      }
    }

    .warning-container {
      background-color: #e4e9f2 !important;

      span {
        display: inline-block;
        font-size: 16px !important;
      }
    }
  }

  &__infos {
    display: flex;
    flex-wrap: wrap;
    // grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    // grid-template-rows: repeat(3, 1fr);
    gap: 1.2rem;
    flex: 7 1 500px;

    &__values {
      flex: 1 1 240px;

      display: flex;
      flex-direction: column;
      background-color: #e4e9f2 !important;
      padding: 13px 24px !important;
    }
  }

  &__infos-field {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 1.2rem;

    @media only screen and (max-width: 768px) {
      grid-template-columns: none;
    }

    .info-sections-wrapper-field {
      display: flex;
      flex-direction: column;
      justify-content: center;
      background-color: #e4e9f2 !important;
      padding: 13px 24px !important;
    }
  }
}
