.favorite-heart {
  height: 40px;
  min-width: 40px;
  background-color: white;
  border: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 250ms;
  cursor: pointer;

  &:hover {
    background-color: #eeebeb;

    svg path {
      fill: #b64040;
    }
  }

  .active {
    path {
      fill: #973131;
    }
  }

  .unactive {
    path {
      fill: #fff;
    }
  }
}
