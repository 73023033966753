.footer-area {
  padding-top: 40px !important;

  @media (min-width: 1600px) {
    .container {
      max-width: 1360px !important;
    }
  }
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;

  padding-top: 0 !important;

  .footer-logo {
    margin-bottom: 56px;
  }
}

.without-border {
  border: transparent !important;
  padding-bottom: 0 !important;
}

.footer-card {
  max-width: 100%;
  flex: 1 1 215px;

  h4 {
    color: var(--main-color-one);
  }

  ul li a {
    color: #999999;
  }

  div {
    h4 {
      font-size: 1.25rem;
      line-height: 1.5rem;
    }

    ul {
      color: #000;
      font-size: 14px;

      .different-link {
        display: flex;
        align-items: center;
        margin-top: 20px;

        &:hover {
          filter: opacity(0.55);
        }

        a {
          color: var(--main-color-one);

          display: flex;
          align-items: center;
          column-gap: 0.5rem;
        }
      }
    }

    @media screen and (max-width: 576px) {
      text-align: center;

      h4 {
        font-size: 1.5rem;
        line-height: 2.1rem;
        margin-bottom: 1rem;
      }
      ul li {
        font-size: 1rem;
        line-height: 1.4rem;
      }

      ul li:last-child {
        justify-content: center;
      }
      ul li a {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}
