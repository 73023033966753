.main-color {
  color: var(--main-color-one);
}

.my-about-title {
  font-size: 28px;
  font-weight: bold;
}

.my-phrases-container {
  text-align: justify;
  display: flex;
  grid-gap: 20px;
  font-size: 14px;
  font-weight: 500;

  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
}

.custom-float-icon-about {
  z-index: 99;
  position: fixed;
  top: 60%;
  right: 20px;
  height: 60px;
  width: 60px;

  svg {
    height: 60px;
    width: 60px;
  }
}

.histories-container {
  display: flex;
  flex-direction: column;
}

.company-container {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }
}

.slide-wrapper {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }
}
