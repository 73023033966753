.blog-area {
  margin-top: 5rem;

  .single-news {
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);

    .details {
      h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .thumb img {
      height: 100px;
      width: 100%;
      object-fit: cover;
    }
  }
}

.details-description {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
