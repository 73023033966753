.image-gallery {
  height: 100%;
  overflow: hidden;
  background-color: #f6f7fb;

  .gallery-image {
    height: 600px;
  }

  .image-gallery-slides {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 10px 10px 10px;
    background-color: #000;
  }

  .image-gallery-thumbnails-wrapper {
    height: 100%;
    transform: translateY(-100px);

    .image-gallery-thumbnails-container {
      img {
        max-height: 69px;
      }

      button {
        aspect-ratio: 90/70;
        border-radius: 10px;
        overflow: hidden;
      }
    }

    .image-gallery-thumbnail.active,
    .image-gallery-thumbnail:focus,
    .image-gallery-thumbnail:hover {
      outline: none;
      border: none;
    }
  }
}
