.service-area {
  width: 100%;

  padding-bottom: 0 !important;
}
.service-area-about {
  padding: 0 30px;
  overflow: hidden;
}

.single-intro {
  height: 100%;
}

.single-intro::after {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px;
}

.service-slider-2 {
  cursor: grab;
  margin-bottom: 1rem;

  .owl-stage-outer {
    .owl-stage {
      display: flex;
    }
  }

  .thumb img {
    color: white;
    width: 20px;
  }

  .owl-nav div {
    position: absolute;
    top: 45%;
    z-index: 1;

    transform: translateY(-50%);
  }

  .owl-next {
    right: -50px;
  }

  .owl-prev {
    left: -50px;
  }

  .owl-nav button i {
    font-size: 25px;
    text-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  @media screen and (max-width: 767px) {
    .owl-stage-outer {
      overflow: visible;
    }
  }
}
