.contact-area {
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;

  .top-wrapper {
    row-gap: 2rem;
    margin-bottom: 60px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }
}
.media-container {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1210px;
  }

  @media (min-width: 1600px) {
    max-width: 1360px;
  }
}

.contact-page-map {
  background-color: #a8a8a8;
}

.contact-description-container {
  margin-bottom: 2.5rem;

  .contact-title {
    font-family: var(--heading-font);
    font-size: 2.25rem;
    color: var(--main-color-one);
    margin-bottom: 1rem;

    @media only screen and (max-width: 500px) {
      font-size: 24px;
    }
  }

  .contact-phrase {
    font-family: var(--heading-font);
    font-size: 18px;
    line-height: 27px;
    color: #000;

    @media only screen and (max-width: 500px) {
      font-size: 14px;
    }
  }

  .form-wrapper {
    display: flex;
    width: 100%;

    column-gap: 1rem;
  }
}

.my-contact-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;

  &__data {
    max-width: 100%;
  }

  @media only screen and (max-width: 992px) {
    grid-template-columns: 1fr;
    grid-gap: 1rem;

    p,
    h5 {
      text-align: center;
    }
  }
}
