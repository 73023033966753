.my-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .contact-description-container {
    font-family: var(--heading-font);
    padding: 0 30px;

    .contact-title {
      font-size: 2.25rem;
      color: var(--main-color-one);
      margin-bottom: 1rem;
    }

    .contact-phrase {
      font-size: 1.125rem;
      line-height: 1.6875rem;
      margin-bottom: 0.5rem;
    }
    .title-margin-top {
      margin-top: 40px;
    }
    .margin-bottom {
      margin-bottom: 1rem !important;
    }
  }
}

.w-85 {
  width: 85%;

  margin-bottom: 30px;
}

.pd-top-50 {
  padding-top: 50px;
}

.form-wrapper {
  display: flex;
  width: 100%;

  column-gap: 1rem;

  .sell-property-form {
    &__contact {
      &__input {
        background-color: #f6f7fb !important;
      }

      &__button {
        width: 100%;
      }
      .contact {
        .input-error {
          font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
          font-weight: 400;
          font-size: 0.75rem;
          line-height: 1.66;
          letter-spacing: 0.03333em;
          text-align: left;
          margin-top: 3px;
          margin-right: 14px;
          margin-bottom: 0;
          margin-left: 14px;

          color: #d32f2f;
        }
        .mt-18 {
          margin-top: 18px;
        }
        .rld-single-input {
          input {
            font-size: 1rem;
            line-height: 1.4375rem;
            font-family: 'Helvetica';
            height: 56px;

            background-color: transparent !important;
            padding: 0 14px;
          }

          p {
            color: #d32f2f;
            font-family: 'Helvetica';
            font-size: 0.75rem;
            margin: 3px 14px 0;
          }
        }

        .rld-single-input select {
          height: 48px;
          line-height: 48px;
          width: 100%;
          padding: 0 20px;
          border: 1px solid #d4d4d4;
          -webkit-transition: all 0.4s ease;
          -moz-transition: all 0.4s ease;
          -o-transition: all 0.4s ease;
          transition: all 0.4s ease;
          border-radius: 4px;
          font-size: 15px;
        }
        .rld-single-input select:hover,
        .rld-single-input select:active,
        .rld-single-input select:focus {
          border: 1px solid var(--main-color-one);
        }

        .test-input input:focus {
          border: 0;
        }
        .test-input textarea:focus {
          border: 0;
        }

        .upload__image-wrapper {
          display: flex;
          flex-direction: column;

          .image-input {
            background-color: #fff;
            outline: none;

            padding: 1.5rem;

            font-size: 0.9375rem;
            width: 100%;
            border: 1px solid #d4d4d4;
            -webkit-transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            transition: all 0.4s ease;
            border-radius: 4px;

            margin-bottom: 1rem;

            &:hover,
            &:active,
            &:focus {
              border: 1px solid var(--main-color-one);
            }
          }

          .images-grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            align-items: center;

            gap: 0.5rem;
            flex-wrap: wrap;
          }
          .image-item {
            position: relative;
            &__btn-wrapper {
              position: absolute;
              top: 5px;
              right: 5px;

              color: #c8232c;
              cursor: pointer;
            }

            img {
              object-fit: cover;
            }
          }
        }

        @media screen and (max-width: 768px) {
          flex-direction: column;
          align-items: center;
        }

        .service-area-about {
          flex: 3;
        }
        .sell-property-form__contact {
          flex: 1;
        }
      }

      .status-wrapper {
        width: 100%;

        padding: 1rem 0 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        font-family: var(--heading-font);
        font-size: 1rem;
        line-height: 100%;
        text-align: center;

        .MuiSvgIcon-root {
          color: var(--main-color-one);
          font-size: 6rem;
          margin-bottom: 0.5rem;
        }
      }
    }

    .mt-18 {
      margin-top: 18px !important;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }

  .service-area-about {
    flex: 3;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }
  .sell-property-form__contact {
    flex: 1;
  }
}
