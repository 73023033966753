.slick-container {
  height: auto;
  max-width: 1085px;
  margin: -89px auto 0;

  .slider {
    overflow: visible;

    * {
      z-index: 1;
    }

    .slick-track {
      display: flex;
      justify-content: center;
    }

    .single-service {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: var(--heading-font);
      border-radius: 1.25rem;
      margin: 10px auto 10px;
      width: 245px !important;

      padding: 2.5rem 2.875rem;

      h4 {
        font-weight: bold;
        font-size: 1.125rem;
        line-height: 1.6875rem;

        align-self: flex-start;

        margin-bottom: 2rem;
      }

      h2 {
        color: var(--main-color-one);
        font-weight: bold;
        font-size: 3.5rem;
        line-height: 5.25rem;
        text-align: left;

        margin: 0;
      }

      p {
        color: #000;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.5rem;
        text-align: left;

        margin-top: -1rem;
        margin-bottom: 0;
      }

      .readmore-btn {
        margin-top: 2.125rem;
        margin-left: 0;

        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: normal;

        align-self: start;

        i {
          right: -40px;
        }
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    margin-top: -50px;
  }

  @media only screen and (max-width: 992px) {
    margin-top: -20px;
  }

  @media only screen and (max-width: 800px) {
    margin-top: 30px;
  }
}
