.recomand-custom-float-icon {
  height: 60px;
  width: 60px;
  position: fixed;
  top: 30%;
  right: 30px;
  z-index: 99;

  svg {
    height: 60px;
    width: 60px;
  }

  @media only screen and (max-width: 680px) {
    position: fixed;
    top: 15%;
    right: 10px;
    z-index: 99;
  }
}

.single-feature {
  margin: 0 !important;

  @media only screen and (max-width: 850px) {
    margin-bottom: 1rem !important;
  }
}

.section-title {
  margin-bottom: 2rem;
  .custom-title {
    color: #000;
    font-weight: 400;
    font-size: 1.5rem;
    line-height: 2.25rem;
    margin: 0;
  }

  .btn-view-all {
    text-decoration: none;
    font-size: 1rem;
    font-weight: normal;
    line-height: 150%;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.image-thumb {
  height: 250px;

  &--main {
    height: 100% !important;

    img {
      height: 100%;
    }
  }

  img {
    height: 100%;
    width: 100%;
  }
}

.image-details {
  height: 225px;
  display: flex;
  flex-direction: column;
  row-gap: 21px;

  &__infos {
    border-bottom: 1.8px solid var(--main-color-one);
    padding-bottom: 0.4rem;
    margin-bottom: 0.4rem;

    a {
      font-weight: normal;
      font-size: 1rem;
      line-height: 120%;
    }

    h6 {
      font-size: 1rem;
      line-height: 120%;
    }

    &__name {
      &:hover {
        color: var(--main-color-one) !important;
      }
    }
  }
}

.subtype {
  text-transform: none;
  &:first-letter {
    text-transform: uppercase;
  }
}
