.main-container {
  margin-top: 14%;
  .slick-slider {
    .slick-slide {
      padding: 0 10px;
    }
  }
  .media-left {
    img {
      height: 62px;
      aspect-ratio: 27/9;

      object-fit: cover;

      @media screen and (max-width: 1200px) {
        aspect-ratio: 16/9;
      }
    }
  }
  .media-body {
    h6 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 630px) {
    margin-top: 24%;
  }

  @media only screen and (max-width: 340px) {
    margin-top: 27%;
  }
}
.news-search-box-show {
  column-gap: 5vw;
  .blog-search-select {
    width: 60%;

    @media screen and (max-width: 540px) {
      width: 100%;
    }
  }
  input,
  select {
    margin-right: 0;
  }
  button {
    position: static;

    font-size: 2rem;
    margin-top: 0;

    padding-right: 1.5rem;
  }
}
