.navbar {
  padding: 0 !important;
  transition: 0.3s ease-in-out;

  &.mobile-menu {
    position: absolute;
    margin-left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;

    display: flex;
    align-items: flex-start;
    background-color: var(--main-color-one);
    z-index: 999;
  }

  &__navbar-container {
    height: 70px;
    width: 100%;
    padding: 0 15px;
    margin: 0 auto;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;

    &.mobile-menu {
      background-color: transparent;
    }

    @media (min-width: 576px) {
      max-width: 540px;
    }

    @media (min-width: 768px) {
      max-width: 720px;
    }

    @media (min-width: 992px) {
      max-width: 960px;
    }

    @media (min-width: 1200px) {
      max-width: 1210px;
    }

    @media (min-width: 1600px) {
      max-width: 1360px;
    }

    @media only screen and (max-width: 630px) {
      top: 0;
      height: 85px;
    }

    @media only screen and (max-width: 800px) {
      justify-content: center;
    }

    &__logo-container {
      margin-right: 5%;

      .logo {
        max-height: 5rem !important;

        @media only screen and (max-width: 780px) {
          height: 4rem !important;
        }
      }

      .logo-white path {
        fill: white !important;
      }

      @media only screen and (max-width: 990px) {
        margin-right: 3%;
      }
    }

    &__navigation {
      width: 90%;
      display: flex;
      align-content: center;
      justify-content: flex-end;

      &__list {
        width: 100%;
        max-width: 780px;
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        margin: 0;
        column-gap: 15px;

        li {
          font-size: 1rem;
          line-height: 1.5rem;
          font-family: var(--heading-font);

          &.active {
            color: var(--main-color-one) !important;

            a {
              color: var(--main-color-one) !important;
            }
          }

          strong a {
            font-weight: bold !important;
          }

          a {
            color: black;
          }

          @media only screen and (max-width: 992px) {
            font-size: 0.875rem;
          }
        }
      }

      &__button {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        border: transparent;
        border-radius: 4px;
        background-color: white;
        color: var(--main-color-one);
        font-size: 16px;
        line-height: 24px;
        font-family: var(--heading-font);

        margin-top: 3rem;
        padding: 23px 0;
      }

      &__footer {
        display: flex;
        justify-content: space-between;
        margin-top: 0.5rem;

        &__infos {
          display: flex;
          flex-direction: column;
          font-family: var(--heading-font);
          text-decoration: none;
          color: white;

          small {
            font-size: 14px;
            margin-bottom: -0.4rem;
          }

          span {
            font-size: 18px;
          }
        }
      }

      @media only screen and (max-width: 845px) {
        width: 100%;
      }

      @media only screen and (max-width: 800px) {
        &:not(.mobile-menu) {
          display: none;
        }

        height: 75%;
        position: absolute;
        top: 20%;
        left: 3%;
        right: 3%;
        width: 94%;
        flex-direction: column;

        &__list {
          flex-direction: column;
          height: 80%;

          li {
            font-size: 22px;

            &.active {
              color: white !important;

              a {
                color: white !important;
              }
            }

            strong a {
              font-weight: bold !important;
              color: #977f5c !important;
            }
          }
        }
      }
    }

    &__mobile-menu {
      display: none;
      justify-content: space-between;
      flex-direction: column;
      position: absolute;
      right: 2rem;
      height: 25px;
      width: 40px;

      @media only screen and (max-width: 800px) {
        display: flex;
      }

      @media only screen and (max-width: 400px) {
        width: 30px;
      }

      .one,
      .two,
      .three {
        height: 2px;
        width: 100%;
        background-color: var(--main-color-one);
        border-radius: 4px;
        transition-duration: 0.3s;
      }

      &.opened {
        margin-top: 20px;

        .one,
        .two,
        .three {
          background-color: white;
        }

        .one {
          transform: rotate(-45deg);
        }
        .two {
          opacity: 0;
        }
        .three {
          transform: rotate(45deg) translateX(-15px) translateY(-16px);
        }
      }
    }
  }
}
