.ms-pagination {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  margin-left: 50%;

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  @media (max-width: 1200px) {
    margin: 0 auto;
  }

  @media (max-width: 480px) {
    width: 65%;
    margin-left: 20%;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  &__item {
    border: 1px solid var(--main-color-one);
    border-radius: 4px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: border-color 250ms, background-color 250ms;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &:not(:last-child) {
      margin-right: 8px;
    }

    &:hover {
      border-color: #b38e54;
      background-color: #f8f8f8;

      color: #b38e54;
    }

    font-weight: bold;
    color: var(--main-color-one);

    &.active {
      border: none;
      background-color: var(--heading-color);

      a {
        color: white !important;
      }

      &:hover {
        background-color: #7c1826;

        a {
          color: #f8f8f8;
        }
      }
    }

    &.disabled {
      border: none !important;
      background-color: #dccfb7 !important;
      cursor: default !important;

      &:hover {
        background-color: #dccfb7 !important;
      }
    }
  }

  .previous-page,
  .next-page {
    background-color: var(--main-color-one);
    a {
      color: white !important;
    }

    &:hover {
      background-color: #b38e54;
      a {
        color: #f8f8f8;
      }
    }
  }
}
